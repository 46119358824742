* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
}
.page-wrapper {
  height: 100%;
}
body,
input {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
body {
  background: url("http://37.media.tumblr.com/ae324e7244c389e47e1095065d6969db/tumblr_n1iq25Wq701st5lhmo1_1280.jpg") no-repeat center center fixed;
  background-image: url("../images/working.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.page-wrapper {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  display: table;
}
.user-block {
  display: table-cell;
  text-align: center;
  width: 400px;
  position: relative;
  vertical-align: middle;
}
.user-block h1 {
  font-family: 'Lato', 'sans-serif';
  font-weight: 900;
  color: #fff;
  font-size: 88px;
  line-height: 1em;
  margin-bottom: 40px;
  margin-top: 32px;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
}
.user-block p {
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
  max-width: 450px;
  margin: 0 auto 40px;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
}
.user-block p a {
  color: #ccc;
}
.user-block p.note {
  font-style: italic;
  margin-top: -20px;
  font-size: 12px;
}
.sticky-bottom {
  text-align: center;
  color: #fff;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.sticky-bottom .sticky-bottom__inner {
  margin-top: -40px;
}
.sticky-bottom .fa-heart {
  color: #c66;
}
.sticky-bottom a {
  color: #b3b3b3;
}
.user-block__inner {
  max-width: 600px;
  margin: 0 auto 40px;
  border-bottom: 2px solid rgba(255,255,255,0.9);
}
.user-block__contact {
  font-family: 'Lato';
  margin-bottom: 80px;
}
.user-block__contact input[type="text"] {
  background-color: transparent;
  padding: 0 4px;
  font-size: 24px;
  border: none;
  outline: none;
  border-bottom: 2px solid #fff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  font-family: 'Lato';
}
.btn.btn--large {
  font-family: 'Lato';
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  padding: 10px 30px;
  border: 3px solid rgba(255,255,255,0.8);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  outline: none;
  top: 0px;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: top 0.2s, background-color 0.2s, padding 0.2s;
  -moz-transition: top 0.2s, background-color 0.2s, padding 0.2s;
  -o-transition: top 0.2s, background-color 0.2s, padding 0.2s;
  -ms-transition: top 0.2s, background-color 0.2s, padding 0.2s;
  transition: top 0.2s, background-color 0.2s, padding 0.2s;
}
.btn.btn--large:hover {
  background-color: #8cb38c;
}
.btn.btn--large:active {
  background-color: #517b51;
}
.user-block__avatar {
  -webkit-border-radius: 220px;
  border-radius: 220px;
  width: 220px;
  height: 220px;
  border: 10px solid rgba(204,204,204,0.9);
  -webkit-box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 14px rgba(255,255,255,0.4);
  box-shadow: 0px 0px 0px 4px #fff, 0px 0px 0px 14px rgba(255,255,255,0.4);
  margin-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .user-block__inner {
    max-width: 480px;
  }
}
@media screen and (max-width: 500px) {
  .user-block__inner {
    max-width: 400px;
  }
}
@media screen and (max-width: 420px) {
  .user-block__inner {
    max-width: 360px;
    margin: 0 auto 20px;
  }
  .user-block__inner h1 {
    margin-bottom: 20px;
  }
  .user-block__inner .note {
    margin-bottom: 15px;
  }
  .user-block__contact {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 320px) {
  .user-block__inner {
    max-width: 280px;
  }
}
